.search_bar_input_wrapper {
  display: flex;
  width: 100%;
}

.search_bar_input_wrapper:focus {
  color: tomato;
}
.search_bar_input {
  width: 90%;
  flex: 1;
  margin: 0;
  border: none;
}

.search_bar_input:focus {
  outline: none;
}

.search_bar_img {
  background: white;

  object-fit: cover;
  width: 10%;
  height: 10%; /* set to 'auto' in IE11 to avoid distortions */
  padding: 5px;
}
