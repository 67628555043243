.search_bar_result {
  background: white;

  padding: 0;
  margin: 0;
  border: none;
  max-height: 15vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.search_bar_result:hover {
  cursor: pointer;
}
.search_bar_result_li {
  display: flex;
}

.search_bar_result_li:hover {
  background: #0633ff;
  color: white;
}
