.panel {
  z-index: 10;
  display: flex;
  position: absolute;
  flex-direction: column;
  margin: 10px;
  width: 238px;
  justify-items: center;
}

.next_button {
  display: flex;
  background: white;
  text-align: center;
  padding: 10px 15px;
  border: 4px solid #0633ff;

  justify-content: center;
  align-items: center;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  font-size: 20px;
  margin: 0;
}

.next_button:hover {
  color: white;
  background: #0633ff;
  transition: 0.3s;
}

.next_button:hover .img_dice {
  background: #0633ff;
  transition: 0.3s;
}

.search_bar_cur_location {
  font-size: 22px;
  background: #0633ff;
  color: white;
  padding-left: 4px;
  padding-top: 2px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.img_dice {
  background: white;
  display: block;
  object-fit: cover;
  width: 25%;
  height: 25%; /* set to 'auto' in IE11 to avoid distortions */
  padding: 5px;
}

.affiliate_img {
  margin-top: 10px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 767px) {
  .next_button {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 5px 7.5px;
    font-size: 16px;
  }
  .panel {
    bottom: 15px;
    left: 0;
    width: 200px;
    pointer-events: none;
  }
  .search_bar_cur_location {
    font-size: 12px;
  }

  .img_dice {
    width: 15%;
    height: 15%;
  }

  .next_button {
    pointer-events: all;
  }

  .affiliate_img {
    width: 60%;
    height: 60%;
    object-fit: cover;
    pointer-events: all;
  }

  a {
    pointer-events: none;
  }
}
