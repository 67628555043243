#pano {
  width: 100%;
  height: 100%;
}
#nav {
  width: 100%;
  height: 230px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 767px) {
  #nav {
    display: none;
  }
}
