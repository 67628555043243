.search_bar_wrapper {
  display: flex;
  flex-direction: column;
  border: 4px solid #0633ff;
  border-top: none;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

@media only screen and (max-width: 767px) {
  .search_bar_wrapper {
    display: none;
  }
}
